import { get } from "@nhi/utils"

export interface IProfession {
    id: number
    name: string
    parent: number | null
    order: number
    obsolete: boolean
}

/**
 * Get all professions
 * @returns All professions
 */
export async function getProfessions() {
    const url = `${process.env.NEXT_PUBLIC_CUSTOMER_MANAGER_API}/api/profession`
    return await get<IProfession[]>(url)
}
