import useUser from "hooks/useUser"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"

import useLocalStorage from "@nhi/hooks/useLocalStorage"
import { isLocalStorageSupported, isPublished } from "@nhi/utils"
import { IPage, getPage } from "data/contentData/api/content"

import { ConfigCacheKeys } from "./config.hooks"

type BannerType = "warning" | "info" | "direct-message"

export interface IBanner extends IPage {
    bannerType: BannerType
    bannerRecipient?: string
}

/**
 *
 * get IPage object from _config/banner from banner content
 *
 * @returns object with a list of banners
 */
async function getBanners(): Promise<IBanner[]> {
    const page = await getPage("/_config/banner/")

    if (!page || !page.children) return []

    const banners: IBanner[] = []
    page.children.forEach(folder => {
        const _banners = folder.children
            ?.filter(p => isPublished(p.published, p.unpublished))
            .map(banner => {
                const bannerType = getSeverity(folder.name)
                const bannerRecipient =
                    bannerType === "direct-message" ? folder.name : undefined
                return {
                    ...banner,
                    bannerType,
                    bannerRecipient
                } as IBanner
            })

        if (_banners) {
            banners.push(..._banners)
        }
    })

    return banners
}

function getSeverity(folderName: string): BannerType {
    if (folderName === "Marketing") {
        return "info"
    }

    if (folderName === "Warnings") {
        return "warning"
    }

    return "direct-message"
}

/**
 * Get banner objects
 * @returns object with 2 lists: warning banners and marketing banners
 */
export function useGetBanners() {
    return useQuery<IBanner[], Error>(
        [ConfigCacheKeys.BANNERS],
        async () => await getBanners()
    )
}

/**
 * Filter banners and return next one to display
 * @param banners list of all available banners
 * @returns banner to display with handleClose-function
 */
export function useGetBanner(banners: IBanner[] | undefined) {
    const { user } = useUser()

    const [bannerWithoutData, setBannerWithoutData] = useState<
        IBanner | undefined
    >()
    const [banner, setBanner] = useState<IBanner | undefined>()
    const [readBannerIds, setReadBannerIds] = useLocalStorage<number[]>(
        "read-banners",
        []
    )

    // Filter out read banners and set the first unread banner to be shown
    useEffect(() => {
        const unreadBanners = banners?.filter(b => {
            // Banner is already read
            if (readBannerIds.includes(b.contentId)) {
                return false
            }

            // Banner is a direct message to another user
            if (
                b.bannerType === "direct-message" &&
                b.bannerRecipient !== user?.parentId
            ) {
                return false
            }

            return true
        })

        const nextBanner =
            unreadBanners && unreadBanners.length > 0
                ? unreadBanners[0]
                : undefined
        setBannerWithoutData(nextBanner)
    }, [banners, readBannerIds, user])

    // Get banner data for the banner that is to be shown
    useEffect(() => {
        if (bannerWithoutData) {
            getPage(bannerWithoutData.url).then(data => {
                const _banner: IBanner = {
                    ...(data as IBanner),
                    bannerType: bannerWithoutData.bannerType
                }
                setBanner(_banner)
            })
        } else {
            setBanner(undefined)
        }
    }, [bannerWithoutData])

    const handleClose = () => {
        if (!isLocalStorageSupported()) return
        if (!bannerWithoutData) return

        setReadBannerIds(p => [...p, bannerWithoutData.contentId])
    }

    return { banner, handleClose }
}
