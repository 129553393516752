import { formatISO, subDays } from "date-fns"
import { useSnackbar } from "notistack"
import * as React from "react"
import { ChangeEvent, useState } from "react"

import Button from "@mui/material/Button"
import { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"

import { IProfile, ProfileFormType } from "data/customerManager/api/profile"
import { useUpdateProfile } from "data/customerManager/hooks/profile.hooks"

import { DAYS_BETWEEN_CONFIRM_USER_DETAILS } from "./ConfirmUserDetails"
import HPRField from "./HPRField"
import SelectProfessionFields from "./SelectProfessionFields"
import WorkPlaceField from "./WorkPlaceField"

interface IProps {
    profile: IProfile
    handleClose: () => void
}

type ChangeEventType =
    | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    | SelectChangeEvent

export default function ConfirmUserDetailsForm({
    profile,
    handleClose
}: IProps) {
    const { professions, ...initialForm } = profile
    const [form, setForm] = useState<ProfileFormType>(initialForm)

    const handleChange = (e: ChangeEventType) => {
        if (e.target.name === "professionId") {
            setForm({
                ...form,
                professionId: parseInt(e.target.value),
                secondaryProfessionId: 0,
                tertiaryProfessionId: 0,
                placeOfWork: ""
            })
        } else {
            setForm({ ...form, [e.target.name]: e.target.value })
        }
    }

    const { mutate } = useUpdateProfile()
    const { enqueueSnackbar } = useSnackbar()
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const _form = {
            ...form,
            lastProfileUpdate: null
        }
        mutate(_form)

        enqueueSnackbar("Dine opplysninger er nå oppdatert.", {
            variant: "info"
        })

        handleClose()
    }

    const handlePostpone = async () => {
        const postponeDate = formatISO(
            subDays(new Date(), DAYS_BETWEEN_CONFIRM_USER_DETAILS - 7)
        )

        const _form = {
            ...form,
            lastProfileUpdate: postponeDate
        }
        await mutate(_form)
        handleClose()
    }

    return (
        <StyledForm onSubmit={handleSubmit}>
            <Typography pt={2}>
                {"Vennligst fyll ut og bekreft dine brukerdetaljer."}
            </Typography>

            <StyledReadOnlyWrapper>
                <div>
                    <Typography sx={{ fontWeight: 700 }}>{"Navn"}</Typography>
                    <Typography>{profile?.name}</Typography>
                </div>

                <div>
                    <Typography sx={{ fontWeight: 700 }}>{"Epost"}</Typography>
                    <Typography>{profile?.email}</Typography>
                </div>
            </StyledReadOnlyWrapper>

            <TextField
                fullWidth
                id="postAddress"
                label={"Adresse"}
                name="postAddress"
                onChange={handleChange}
                value={form.postAddress}
                variant="outlined"
                required
            />
            <StyledPostalWrapper>
                <TextField
                    fullWidth
                    id="zip"
                    label={"Postnummer"}
                    name="zip"
                    onChange={handleChange}
                    value={form.zip}
                    variant="outlined"
                    inputProps={{ minLength: 4 }}
                    required
                />
                <TextField
                    fullWidth
                    id="city"
                    label={"Poststed"}
                    name="city"
                    onChange={handleChange}
                    value={form.city}
                    variant="outlined"
                    required
                />
            </StyledPostalWrapper>

            <SelectProfessionFields
                form={form}
                professions={professions}
                onChange={handleChange}
            />

            <HPRField
                form={form}
                onChange={handleChange}
            />
            <WorkPlaceField
                form={form}
                onChange={handleChange}
            />

            <Typography>
                {
                    'Ved å trykke på "Minn meg på det senere" utsetter du oppdateringer i 7 dager.'
                }
            </Typography>

            <StyledButtonWrapper>
                <Button
                    variant="outlined"
                    onClick={handlePostpone}>
                    {"Minn meg på det senere"}
                </Button>
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained">
                    {"Bekreft og fortsett"}
                </Button>
            </StyledButtonWrapper>
        </StyledForm>
    )
}

const StyledReadOnlyWrapper = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "1rem",
    marginTop: ".5rem",
    marginBottom: ".5rem"
}))

const StyledPostalWrapper = styled("div")(() => ({
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
    gap: "1rem"
}))

const StyledButtonWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    justifyContent: "flex-end"
}))

const StyledForm = styled("form")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2)
}))
