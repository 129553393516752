import * as React from "react"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { ProfileFormType } from "data/customerManager/api/profile"

import { STUDENT_PROFESSION_ID } from "./ConfirmUserDetails"

export const WORKPLACES = [
    "Velg arbeidssted",
    "Institusjon offentlig",
    "Institusjon privat",
    "Legekontor kommunalt",
    "Legekontor privat",
    "Legevakt",
    "Privat praksis",
    "Sykehjem offentlig",
    "Sykehjem privat",
    "Sykehus privat",
    "Sykehus offentlig",
    "Universitet",
    "Annet"
]
export const STUDENT_WORKPLACES = [
    "Velg arbeidssted",
    "NMBU",
    "Nord universitet",
    "NTNU",
    "OsloMet",
    "UiA",
    "UiB",
    "UiO",
    "UiS",
    "UiT",
    "Universitetet i Sørøst-Norge",
    "Annen høyskole",
    "Annet universitet"
]

interface IProps {
    form: ProfileFormType
    onChange: (e: SelectChangeEvent) => void
}

export default function WorkPlaceField({ form, onChange }: IProps) {
    const workplaces =
        form.professionId === STUDENT_PROFESSION_ID
            ? STUDENT_WORKPLACES
            : WORKPLACES

    const label =
        form.professionId === STUDENT_PROFESSION_ID
            ? "Studiested *"
            : "Arbeidssted *"
    const value = workplaces.find(p => p === form.placeOfWork) ?? ""

    return (
        <FormControl>
            <InputLabel id="placeOfWork">{label}</InputLabel>
            <Select
                fullWidth
                id="placeOfWork"
                name="placeOfWork"
                label={label}
                onChange={onChange}
                value={value}
                variant="outlined"
                required>
                {workplaces.map(p => (
                    <MenuItem
                        key={p}
                        value={p}
                        selected={p === form.placeOfWork}>
                        {p}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
