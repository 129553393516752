import * as React from "react"

/**
 * Function that uses localStorage for persistence between sessions, but behaves like useState
 * @param key value of key in localstorage
 * @param initialValue default value if none is set in local storage
 * @returns [state, setState]
 */
export default function useLocalStorage<T>(key: string, initialValue: T) {
  const [storedValue, setStoredValue] = React.useState<T>(() => {
    try {
      if (typeof window === "undefined") {
        return initialValue
      }
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      return initialValue
    }
  })
  const setValue = (value: T | ((val: T) => T)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value

      setStoredValue(valueToStore)
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {}
  }
  return [storedValue, setValue] as const
}
