import { general } from "config/config"
import useUser from "hooks/useUser"
import { useMutation, useQuery } from "react-query"

import isEnabled from "lib/is-enabled"

import { OptionsType } from "../../../@types/shared.types"
import { IProfile, getProfile, updateProfile } from "../api/profile"
import { ProfileFormType } from "../api/profile"

enum ProfileCacheKeys {
    PROFILE = "profile"
}

/**
 * Get profile of the authenticated user
 * @param options React Query options
 * @returns Profile of the authenticated user
 */
export function useGetProfile(options?: OptionsType<IProfile>) {
    const { authenticated, accessToken, isFetching, user } = useUser()

    const enabled =
        isEnabled(authenticated && !isFetching, options) &&
        general.enableConfirmUserDetails

    return useQuery<IProfile, Error>(
        [ProfileCacheKeys.PROFILE, user?.guid],

        async () => await getProfile(accessToken),
        {
            ...options,
            enabled
        }
    )
}

/**
 * Update profile of the authenticated user
 * @returns Mutation function to update profile
 */
export function useUpdateProfile() {
    const { accessToken } = useUser()

    return useMutation(async (profile: ProfileFormType) => {
        await updateProfile(profile, accessToken)
    })
}
