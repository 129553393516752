import PortableText from "components/PortableText/PortableText"
import React from "react"

import Alert from "@mui/material/Alert"
import Container from "@mui/material/Container"
import IconButton from "@mui/material/IconButton"
import { styled } from "@mui/material/styles"

import {
    useGetBanner,
    useGetBanners
} from "data/contentData/hooks/banner.hooks"

import Icon from "./Icon"

export function Banner() {
    const { data: banners } = useGetBanners()
    const { banner, handleClose } = useGetBanner(banners)

    if (!banner) return null

    const severity = banner.bannerType === "warning" ? "warning" : "info"

    return (
        <StyledAlert
            action={
                <IconButton
                    sx={{ p: 1 }}
                    size="small"
                    onClick={handleClose}>
                    <Icon
                        name="close"
                        fontSize="inherit"
                    />
                </IconButton>
            }
            severity={severity}
            icon={false}
            onClose={handleClose}>
            <Container maxWidth="lg">
                <PortableText value={banner.blockContent} />
            </Container>
        </StyledAlert>
    )
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    padding: theme.spacing(1, 2),
    alignItems: "center",
    "& .MuiAlert-action": {
        margin: 0,
        padding: 0
    },
    "& .MuiAlert-message": {
        flexGrow: 1,
        "& p": {
            margin: 0
        }
    }
}))
