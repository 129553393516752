import * as React from "react"

import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { styled } from "@mui/material/styles"

import { IProfession } from "data/customerManager/api/profession"
import { ProfileFormType } from "data/customerManager/api/profile"

import {
    OTHER_HEALTH_PERSONNEL_PROFESSION_ID,
    PHYSICIAN_PROFESSION_ID,
    STUDENT_PROFESSION_ID
} from "./ConfirmUserDetails"

interface IProps {
    form: ProfileFormType
    professions: IProfession[]
    onChange: (e: SelectChangeEvent) => void
}

export default function SelectProfessionFields({
    form,
    professions,
    onChange
}: IProps) {
    let ProfessionPartDeuxComponent = null
    if (form.professionId === PHYSICIAN_PROFESSION_ID) {
        const physicianProfessions =
            professions.filter(p => p.parent === PHYSICIAN_PROFESSION_ID) ?? []

        ProfessionPartDeuxComponent = (
            <>
                <ProfessionSelect
                    id="secondaryProfessionId"
                    label="Spesialisering 1"
                    professionId={form.secondaryProfessionId}
                    professions={physicianProfessions}
                    onChange={onChange}
                    required
                />
                <ProfessionSelect
                    id="tertiaryProfessionId"
                    label="Spesialisering 2"
                    professionId={form.tertiaryProfessionId}
                    professions={physicianProfessions}
                    onChange={onChange}
                />
            </>
        )
    }

    if (form.professionId === STUDENT_PROFESSION_ID) {
        const studentProfessions =
            professions.filter(p => p.parent === STUDENT_PROFESSION_ID) ?? []

        ProfessionPartDeuxComponent = (
            <ProfessionSelect
                id="secondaryProfessionId"
                label="Studieretning"
                professionId={form.secondaryProfessionId}
                professions={studentProfessions}
                onChange={onChange}
                required
            />
        )
    }

    if (form.professionId === OTHER_HEALTH_PERSONNEL_PROFESSION_ID) {
        const otherProfessions =
            professions.filter(
                p => p.parent === OTHER_HEALTH_PERSONNEL_PROFESSION_ID
            ) ?? []

        ProfessionPartDeuxComponent = (
            <ProfessionSelect
                id="secondaryProfessionId"
                label="Yrke"
                professionId={form.secondaryProfessionId}
                professions={otherProfessions}
                onChange={onChange}
                required
            />
        )
    }

    const parentProfessions = professions.filter(p => p.parent === null) ?? []
    return (
        <ProfessionWrapper>
            <ProfessionSelect
                id="professionId"
                label="Yrkeskategori"
                professionId={form.professionId}
                professions={parentProfessions}
                onChange={onChange}
                required
            />

            {ProfessionPartDeuxComponent}
        </ProfessionWrapper>
    )
}

interface IProfessionSelectProps {
    id: string
    label: string
    professionId: number
    professions: IProfession[]
    onChange: (e: SelectChangeEvent) => void
    required?: boolean
}

function ProfessionSelect({
    id,
    label,
    professionId,
    professions,
    onChange,
    required = false
}: IProfessionSelectProps) {
    const value = professionId === 0 ? "" : professionId.toString()

    const _label = required ? `${label} *` : label
    return (
        <FormControl>
            <InputLabel id={id}>{_label}</InputLabel>
            <Select
                fullWidth
                id={id}
                name={id}
                label={_label}
                onChange={onChange}
                value={value}
                variant="outlined"
                required={required}>
                {professions.map(p => (
                    <MenuItem
                        key={p.id}
                        value={p.id}
                        selected={p.id === professionId}>
                        {p.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const ProfessionWrapper = styled("div")(() => ({
    margin: "1rem 0",
    display: "flex",
    flexDirection: "column",
    gap: "1rem"
}))
